import React from 'react';
import {
	Col,
	Container,
	Row,
} from 'react-bootstrap';

export default function BorderLayout(props) {
	const {
		top,
		topStyle,
		left,
		leftStyle,
		center,
		centerStyle,
		right,
		rightStyle,
		bottom,
		bottomStyle,
		...otherProps
	} = props;

	return (
		<>
			<Container fluid className="px-0" {...otherProps}>
				{
					top && (
						<Row className="mx-0" style={topStyle}>
							<Col className="px-0">
								{top}
							</Col>
						</Row>
					)
				}
				{
					(left || center || right) && (
						<Row className="mx-0">
							{
								left && (
									<Col sm={3} className="px-0">
										{left}
									</Col>
								)
							}
							{
								center && (
									<Col sm={6 + (!left && 3) + (!right && 3)} className="px-0">
										{center}
									</Col>
								)
							}
							{
								right && (
									<Col sm={3} className="px-0">
										{right}
									</Col>
								)
							}
						</Row>
					)
				}
				{
					bottom && (
						<Row className="mx-0">
							<Col className="px-0">
								{bottom}
							</Col>
						</Row>
					)
				}
			</Container>
		</>
	);
}