import { useEffect, useState } from 'react';
import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  FormControl,
  InputGroup,
  Row,
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBone,
  faCarrot,
  faCheese,
  faCoffee,
  faCube,
  faEgg,
  faFish,
  faHippo,
  faPepperHot,
  faPills,
  faSearch,
  faShoppingBag,
  faSpinner,
  faStethoscope,
  faTrash,
  faTint,
  faTshirt,
  faTree,
  faUtensils,
  faWineGlassAlt,
  faQuestionCircle,
  faChair,
  faShoePrints,
  faRibbon,
  faBed,
  faTemperatureHigh,
  faArchive,
  faHandsWash,
  faDice,
  faGuitar,
  faBaseballBall,
  faFilm,
  faPenAlt,
  faImage,
  faMapPin,
  faGem,
  faCubes,
  faGasPump,
  faFireAlt,
  faLayerGroup,
  faScroll,
  faTape,
  faCompactDisc,
  faGripLinesVertical,
  faVials,
  faVial,
  faMortarPestle,
  faHammer,
  faMedal,
  faCog,
  faDotCircle,
  faSlash,
  faIndustry,
  faPrescriptionBottle,
  faTrashRestore,
  faBurn,
  faCogs,
  faBiohazard,
  faWind,
  faDigitalTachograph,
  faRobot,
  faTools,
  faTruckLoading,
  faBacon,
  faFilter,
  faMeteor,
  faTruckMonster,
  faFireExtinguisher,
  faTractor,
  faGavel,
  faLink,
  faTablet,
  faTrain,
  faCar,
  faBicycle,
  faDolly,
  faShip,
  faPlane,
  faServer,
  faCode,
  faPhoneAlt,
  faMicrochip,
  faPrint,
  faCut,
  faYenSign,
  faCalculator,
  faPhotoVideo,
  faVolumeUp,
  faWeight,
  faClock,
  faCamera,
  faSyringe,
  faBomb,
  faScrewdriver,
} from '@fortawesome/free-solid-svg-icons';

import {
  faGem as faGemRegular,
  faLightbulb as faLightbulbRegular,
  faSnowflake as faSnowflakeRegular,
  faSun as faSunRegular,
} from '@fortawesome/free-regular-svg-icons';

import './App.css';
import categories from './categories';
import datas from './datas';
import BorderLayout from './components/BorderLayout';

const CATEGORIES = {};
categories.forEach(x => {
  CATEGORIES[x.n] = x;
})

const codeToIcon = {
  '01': faHippo,
  '02': faBone,
  '03': faTree,
  '04': faGemRegular,
  '05': faCubes,
  '06': faGem,
  '07': faTint,
  '08': faGasPump,
  '09': faFireAlt,
  '10': faLayerGroup,
  '11': faCompactDisc,
  '12': faGripLinesVertical,
  '13': faScroll,
  '14': faTape,
  '15': faVials,
  '16': faVial,
  '17': faMortarPestle,
  '18': faHammer,
  '19': faMedal,
  '20': faCube,
  '21': faCog,
  '22': faSlash,
  '23': faDotCircle,
  '24': faIndustry,
  '25': faPrescriptionBottle,
  '26': faTrashRestore,
  '27': faBurn,
  '28': faCogs,
  '29': faBiohazard,
  '30': faDigitalTachograph,
  '31': faWind,
  '32': faSunRegular,
  '33': faRobot,
  '34': faTools,
  '35': faTruckLoading,
  '36': faBacon,
  '37': faPrint,
  '38': faFilter,
  '39': faMeteor,
  '40': faTractor,
  '41': faFireExtinguisher,
  '42': faTruckMonster,
  '43': faGavel,
  '44': faLink,
  '45': faTablet,
  '46': faTrain,
  '47': faCar,
  '48': faBicycle,
  '49': faDolly,
  '50': faShip,
  '51': faPlane,
  '52': faServer,
  '53': faCode,
  '54': faPhoneAlt,
  '55': faMicrochip,
  '56': faSnowflakeRegular,
  '57': faCut,
  '58': faYenSign,
  '59': faCalculator,
  '60': faPhotoVideo,
  '61': faVolumeUp,
  '62': faLightbulbRegular,
  '63': faWeight,
  '64': faClock,
  '65': faCamera,
  '66': faSyringe,
  '67': faBomb,
  '68': faScrewdriver,
  '69': faCarrot,
  '70': faEgg,
  '71': faFish,
  '72': faCoffee,
  '73': faCheese,
  '74': faFish,
  '75': faPepperHot,
  '76': faWineGlassAlt,
  '77': faUtensils,
  '78': faTshirt,
  '79': faShoppingBag,
  '80': faShoePrints,
  '81': faRibbon,
  '82': faBed,
  '83': faChair,
  '84': faTemperatureHigh,
  '85': faArchive,
  '86': faStethoscope,
  '87': faPills,
  '88': faHandsWash,
  '89': faDice,
  '90': faGuitar,
  '91': faBaseballBall,
  '92': faFilm,
  '93': faPenAlt,
  '94': faImage,
  '95': faMapPin,
  '96': faTrash,


  '99': faQuestionCircle,
};

const Top = () => (
  <div className="text-light bg-primary py-4">
    <div className="d-flex flex-column justify-content-center align-items-center">
      <h1>サプライチェーン排出量 算定サポート</h1>
      <div className="d-none d-md-block">日本標準商品分類から 部門名 をちょっとだけ簡単に確認できるツール(Cat1,4,9向け)</div>
    </div>
  </div>
);

const Bottom = () => (
  <div className="pt-3 d-flex flex-column align-items-center justify-content-center" style={{ color: 'gray', fontSize: 12, backgroundColor: '#1d6a9620' }}>
    <a href="https://neko-note.org/">「それなら猫の手で」</a>
    <div className="mb-1">Copyright © 2021</div>
    <small>
      <div className="text-center mb-1">
        免責事項<br />
        コンテンツについて、掲載内容の正確性・完全性・信頼性・最新性は保証しておりません。また、掲載されている情報を利用することで発生した紛争や損害に対し、責任を負わないものとします。
      </div>
    </small>
  </div>
);

const Search = (props) => {
  const {
    keyword,
    setKeyword,
  } = props;

  const [text, setText] = useState(keyword);
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    setLoading(true);
  };

  useEffect(() => {
    if (loading) {
      setTimeout(() => {
        setKeyword(text);
        setLoading(false);
      }, 500)
    }
  }, [loading])

  return (
    <>
      <Form noValidate onSubmit={handleSubmit}>
        <InputGroup className="mb-3">
          <FormControl
            placeholder="検索 : 検索したいキーワード（例：びーる）"
            aria-label="keyword"
            aria-describedby="keyword-addon"
            style={{
              borderTopLeftRadius: '50rem',
              borderBottomLeftRadius: '50rem',
            }}
            defaultValue={keyword}
            onChange={(x) => setText(x.target.value)}
          />
          <Button
            type="submit"
            className="pe-3"
            variant="primary"
            id="keyword-addon"
            style={{
              borderTopRightRadius: '50rem',
              borderBottomRightRadius: '50rem',
            }}
          >
            <FontAwesomeIcon icon={loading ? faSpinner : faSearch} className={loading ? 'fa-spin' : ''} />
          </Button>
        </InputGroup>
      </Form>
    </>
  );
}

const Item = (props) => {
  const {
    code,
    name,
    enName,
    description,
    category,
  } = props;

  return (
    <Card className="m-1">
      <Card.Header style={{ backgroundColor: '#1d6a9620' }}>
        <span style={{ width: 32 }} >
          <FontAwesomeIcon className="me-2 text-secondary" icon={codeToIcon[code.slice(0, 2)]} />
        </span>
        {name}
        <span className="text-secondary">
          <small>
            &nbsp;/&nbsp;{enName}
          </small>
        </span>
      </Card.Header>
      <Card.Body className="py-1">
        <Container fluid className="px-0">
          <Row>
            <Col xs={12} lg={6}>
              <div className="d-flex align-items-baseline">
                <span className="pe-2" style={{ color: "#777777", fontSize: 10 }}>
                  分類コード
                </span>
                <span style={{ fontSize: 14 }}>[ {code} ]</span>
              </div>
              <div className="mb-2" style={{ color: "#777777", fontSize: 10, borderBottom: 'dotted 2px rgba(29, 106, 150, 0.125)' }}>
                参考 {description}
              </div>
            </Col>
            <Col xs={12} lg={6}>
              <Container fluid className="px-0">
                <Row>
                  <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                    <div style={{ marginBottom: 1, color: "#777777", fontSize: 10 }}>
                      産業連関表上の部門名
                    </div>
                  </Col>
                  <Col xs={7}>
                    <Badge bg="primary" className="mb-1" style={{ fontSize: 14 }}>{category}</Badge>
                  </Col>
                </Row>
              </Container>
              {/* <div className="d-flex justify-content-end align-items-baseline">
                <div className="ps-2" style={{ borderBottom: 'dotted 2px rgba(29, 106, 150, 0.125)' }}>
                  <span className="pe-2" style={{ fontSize: 10 }}>
                    産業連関表上の部門名
                  </span>
                  <Badge bg="primary" className="mb-1" style={{ fontSize: 14 }}>{category}</Badge>
                </div>
              </div> */}
              <div style={{ color: "#777777", fontSize: 10 }}>
                <Container fluid className="px-0">
                  <Row>
                    <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                      <div style={{ marginBottom: 1 }}>
                        部門コード
                      </div>
                    </Col>
                    <Col xs={7}>
                      <span style={{ fontSize: 14 }}>[ {CATEGORIES[category].i} ]</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                      <div style={{ marginBottom: 1 }}>
                        物量ベースの排出原単位
                      </div>
                    </Col>
                    <Col xs={7}>
                      <span style={{ fontSize: 14 }}>{CATEGORIES[category].v1}</span>
                      <span style={{ fontSize: 10 }}> {CATEGORIES[category].v1 ? 't-CO2eq' : ''}/{CATEGORIES[category].v1u}</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                      <div style={{ marginBottom: 1 }}>
                        生産者価格ベースの排出原単位
                      </div>
                    </Col>
                    <Col xs={7}>
                      <span style={{ fontSize: 14 }}>{CATEGORIES[category].v2}</span>
                      <span style={{ fontSize: 10 }}> t-CO2eq/百万円</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                      <div style={{ marginBottom: 1 }}>
                        購入者価格ベースの排出原単位
                      </div>
                    </Col>
                    <Col xs={7}>
                      <span style={{ fontSize: 14 }}>{CATEGORIES[category].v3}</span>
                      <span style={{ fontSize: 10 }}> t-CO2eq/百万円</span>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={5} className="d-flex flex-column justify-content-end align-items-end">
                      <div style={{ marginBottom: 1 }}>
                        単価（参考）
                      </div>
                    </Col>
                    <Col xs={7}>
                      <span style={{ fontSize: 14 }}>{CATEGORIES[category].v4}</span>
                      <span style={{ fontSize: 10 }}> {CATEGORIES[category].v4 ? '百万円' : ''}/{CATEGORIES[category].v4u}</span>
                    </Col>
                  </Row>
                </Container>
              </div>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

function App() {

  const [keyword, setKeyword] = useState('');

  const keywords = keyword.split(/\s/).filter(x => x !== '');
  const displayItem = keywords.length === 0 ? [] : datas.filter(x => {
    return keywords.every(k => (
      (x.n.indexOf(k) !== -1) ||
      (x.h.indexOf(k) !== -1) ||
      (x.e.indexOf(k) !== -1) ||
      (x.k.indexOf(k) !== -1)
    ));
  }).slice(0, 100);

  const center = (
    <div className="d-flex flex-column py-5" style={{ minHeight: "calc(100vh - 1.8vw - 1.7rem - 0.67em - 162px)" }}>
      <Container className="pb-3">
        <Row>
          <Col xs={12}>
            <Search keyword={keyword} setKeyword={setKeyword} />
          </Col>
          {
            keywords.length === 0
              ? (
                <Col xs={12} className="pt-5 text-center" style={{ color: '#777777' }}>
                  <p>
                    〇 検索できるデータについて<br /><br />
                    <i>日本標準商品分類</i> の 平成2年[1990年]6月改定 相当のデータから商品を検索できます。<br />
                    検索された商品には、産業連関表上の部門名 が合わせて表示されます。<br /><br />
                    この 産業連関表上の部門名 は、<i>排出原単位データベース(Ver.3.1)</i> の <br />
                    <i>表5. 産業連関表ベースの排出原単位（GLIO:2005年表）</i> の部門名に相当する（といいな～）。<br /><br />
                    検索結果は最大で 100件 まで表示されます。<br /><br />
                    データの 正確性・完全性・信頼性・最新性など は保証しておりません。<br />
                    ご利用は自己責任でお願いいたします。
                  </p>
                </Col>
              )
              : (
                <Col xs={12} className="text-end">
                  {displayItem.length} 件{displayItem.length >= 100 ? '以上' : ''} の検索結果があります
                </Col>
              )
          }
        </Row>
      </Container>
      <Container>
        <Row>
          {
            displayItem.map(x => {
              const {
                i,
                n,
                e,
                c,
                d,
              } = x;

              return (
                <Col key={i} xs={12}>
                  <Item code={i} name={n} enName={e} category={c} description={d} />
                </Col>
              );
            })
          }
        </Row>
      </Container>
    </div >
  );

  return (
    <div>
      <BorderLayout
        top={<Top />}
        center={center}
        bottom={<Bottom />}
      />
    </div>
  );
}

export default App;
