const categories = [
  { i: "11101", n: "米", v1: "-", v1u: "", v2: "6.26 ", v3: "5.37 ", v4: "-", v4u: "" },
  { i: "11102", n: "麦類", v1: "-", v1u: "", v2: "6.04 ", v3: "5.10 ", v4: "-", v4u: "" },
  { i: "11201", n: "いも類", v1: "-", v1u: "", v2: "3.80 ", v3: "2.85 ", v4: "-", v4u: "" },
  { i: "11202", n: "豆類", v1: "-", v1u: "", v2: "5.52 ", v3: "4.69 ", v4: "-", v4u: "" },
  { i: "11301", n: "野菜", v1: "-", v1u: "", v2: "4.48 ", v3: "3.28 ", v4: "-", v4u: "" },
  { i: "11401", n: "果実", v1: "-", v1u: "", v2: "3.64 ", v3: "2.66 ", v4: "-", v4u: "" },
  { i: "11501", n: "砂糖原料作物", v1: "-", v1u: "", v2: "4.36 ", v3: "3.98 ", v4: "-", v4u: "" },
  { i: "11502", n: "飲料用作物", v1: "-", v1u: "", v2: "12.76 ", v3: "9.47 ", v4: "-", v4u: "" },
  { i: "11509", n: "その他の食用耕種作物", v1: "-", v1u: "", v2: "7.56 ", v3: "6.06 ", v4: "-", v4u: "" },
  { i: "11601", n: "飼料作物", v1: "-", v1u: "", v2: "10.02 ", v3: "9.72 ", v4: "-", v4u: "" },
  { i: "11602", n: "種苗", v1: "-", v1u: "", v2: "4.36 ", v3: "3.55 ", v4: "-", v4u: "" },
  { i: "11603", n: "花き・花木類", v1: "-", v1u: "", v2: "8.08 ", v3: "5.35 ", v4: "-", v4u: "" },
  { i: "11609", n: "その他の非食用耕種作物", v1: "-", v1u: "", v2: "4.45 ", v3: "3.99 ", v4: "-", v4u: "" },
  { i: "12101", n: "酪農", v1: "-", v1u: "", v2: "12.19 ", v3: "11.11 ", v4: "-", v4u: "" },
  { i: "12102", n: "鶏卵", v1: "-", v1u: "", v2: "8.54 ", v3: "7.09 ", v4: "-", v4u: "" },
  { i: "12103", n: "肉鶏", v1: "-", v1u: "", v2: "10.14 ", v3: "9.56 ", v4: "-", v4u: "" },
  { i: "12104", n: "豚", v1: "-", v1u: "", v2: "9.81 ", v3: "8.85 ", v4: "-", v4u: "" },
  { i: "12105", n: "肉用牛", v1: "-", v1u: "", v2: "15.43 ", v3: "14.24 ", v4: "-", v4u: "" },
  { i: "12109", n: "その他の畜産", v1: "-", v1u: "", v2: "6.25 ", v3: "4.26 ", v4: "-", v4u: "" },
  { i: "13101", n: "獣医業", v1: "-", v1u: "", v2: "1.59 ", v3: "1.59 ", v4: "-", v4u: "" },
  { i: "13102", n: "農業サービス（除獣医業）", v1: "-", v1u: "", v2: "3.53 ", v3: "3.53 ", v4: "-", v4u: "" },
  { i: "21101", n: "育林", v1: "-", v1u: "", v2: "0.50 ", v3: "0.50 ", v4: "-", v4u: "" },
  { i: "21201", n: "素材", v1: "-", v1u: "", v2: "1.98 ", v3: "1.76 ", v4: "-", v4u: "" },
  { i: "21301", n: "特用林産物（含狩猟業）", v1: "-", v1u: "", v2: "5.69 ", v3: "4.37 ", v4: "-", v4u: "" },
  { i: "31101", n: "海面漁業", v1: "-", v1u: "", v2: "9.68 ", v3: "7.28 ", v4: "-", v4u: "" },
  { i: "31104", n: "海面養殖業", v1: "-", v1u: "", v2: "5.16 ", v3: "3.97 ", v4: "-", v4u: "" },
  { i: "31201", n: "内水面漁業・養殖業", v1: "-", v1u: "", v2: "6.16 ", v3: "4.30 ", v4: "-", v4u: "" },
  { i: "61101", n: "金属鉱物", v1: "0.539 ", v1u: "含有量t", v2: "7.95 ", v3: "7.14 ", v4: "0.06718 ", v4u: "含有量t" },
  { i: "62101", n: "窯業原料鉱物", v1: "0.00653 ", v1u: "t", v2: "8.07 ", v3: "5.99 ", v4: "0.000798 ", v4u: "t" },
  { i: "62201", n: "砂利・採石", v1: "0.0118 ", v1u: "t", v2: "5.86 ", v3: "3.32 ", v4: "0.001978 ", v4u: "t" },
  { i: "62202", n: "砕石", v1: "6.69 ", v1u: "千t", v2: "5.70 ", v3: "3.78 ", v4: "1.152 ", v4u: "千t" },
  { i: "62909", n: "その他の非金属鉱物", v1: "0.097 ", v1u: "t", v2: "9.89 ", v3: "8.04 ", v4: "0.009672 ", v4u: "t" },
  { i: "71101", n: "石炭・原油・天然ガス", v1: "0.168 ", v1u: "t", v2: "8.06 ", v3: "7.56 ", v4: "0.02075 ", v4u: "t" },
  { i: "111101", n: "と畜（含肉鶏処理）", v1: "6.04 ", v1u: "t", v2: "10.51 ", v3: "7.75 ", v4: "0.5366 ", v4u: "t" },
  { i: "111201", n: "肉加工品", v1: "9.60 ", v1u: "t", v2: "6.74 ", v3: "5.38 ", v4: "1.317 ", v4u: "t" },
  { i: "111202", n: "畜産びん・かん詰", v1: "3.46 ", v1u: "t", v2: "4.25 ", v3: "3.06 ", v4: "0.6153 ", v4u: "t" },
  { i: "111203", n: "酪農品", v1: "1.71 ", v1u: "kl", v2: "7.72 ", v3: "6.18 ", v4: "0.2090 ", v4u: "kl" },
  { i: "111301", n: "冷凍魚介類", v1: "3.13 ", v1u: "t", v2: "5.68 ", v3: "4.55 ", v4: "0.5051 ", v4u: "t" },
  { i: "111302", n: "塩・干・くん製品", v1: "5.86 ", v1u: "t", v2: "5.25 ", v3: "3.77 ", v4: "0.9175 ", v4u: "t" },
  { i: "111303", n: "水産びん・かん詰", v1: "6.02 ", v1u: "t", v2: "4.97 ", v3: "3.53 ", v4: "0.9740 ", v4u: "t" },
  { i: "111304", n: "ねり製品", v1: "3.01 ", v1u: "t", v2: "4.48 ", v3: "3.69 ", v4: "0.6019 ", v4u: "t" },
  { i: "111309", n: "その他の水産食品", v1: "5.02 ", v1u: "t", v2: "4.00 ", v3: "3.27 ", v4: "1.085 ", v4u: "t" },
  { i: "111401", n: "精穀", v1: "1.73 ", v1u: "t", v2: "5.11 ", v3: "4.26 ", v4: "0.3128 ", v4u: "t" },
  { i: "111402", n: "製粉", v1: "0.922 ", v1u: "t", v2: "10.73 ", v3: "8.52 ", v4: "0.08361 ", v4u: "t" },
  { i: "111501", n: "めん類", v1: "3.57 ", v1u: "t", v2: "5.11 ", v3: "4.13 ", v4: "0.6280 ", v4u: "t" },
  { i: "111502", n: "パン類", v1: "3.91 ", v1u: "t", v2: "4.15 ", v3: "3.54 ", v4: "0.8327 ", v4u: "t" },
  { i: "111503", n: "菓子類", v1: "4.89 ", v1u: "t", v2: "3.86 ", v3: "3.26 ", v4: "1.098 ", v4u: "t" },
  { i: "111601", n: "農産びん・かん詰", v1: "2.11 ", v1u: "t", v2: "4.62 ", v3: "3.22 ", v4: "0.3660 ", v4u: "t" },
  { i: "111602", n: "農産保存食料品（除びん・かん詰）", v1: "1.78 ", v1u: "t", v2: "3.33 ", v3: "2.57 ", v4: "0.3946 ", v4u: "t" },
  { i: "111701", n: "砂糖", v1: "1.102 ", v1u: "t", v2: "8.19 ", v3: "5.85 ", v4: "0.1253 ", v4u: "t" },
  { i: "111702", n: "でん粉", v1: "0.514 ", v1u: "t", v2: "11.87 ", v3: "9.07 ", v4: "0.04229 ", v4u: "t" },
  { i: "111703", n: "ぶどう糖・水あめ・異性化糖", v1: "0.748 ", v1u: "t", v2: "11.49 ", v3: "9.15 ", v4: "0.06272 ", v4u: "t" },
  { i: "111704", n: "植物油脂", v1: "0.948 ", v1u: "t", v2: "11.42 ", v3: "9.22 ", v4: "0.08062 ", v4u: "t" },
  { i: "111705", n: "動物油脂", v1: "0.743 ", v1u: "t", v2: "8.47 ", v3: "7.31 ", v4: "0.08545 ", v4u: "t" },
  { i: "111706", n: "調味料", v1: "2.22 ", v1u: "t", v2: "4.10 ", v3: "3.39 ", v4: "0.4881 ", v4u: "t" },
  { i: "111901", n: "冷凍調理食品", v1: "2.47 ", v1u: "t", v2: "5.00 ", v3: "3.90 ", v4: "0.4159 ", v4u: "t" },
  { i: "111902", n: "レトルト食品", v1: "3.57 ", v1u: "t", v2: "4.75 ", v3: "3.83 ", v4: "0.6697 ", v4u: "t" },
  { i: "111903", n: "そう菜・すし・弁当", v1: "-", v1u: "", v2: "4.35 ", v3: "3.51 ", v4: "-", v4u: "" },
  { i: "111904", n: "学校給食（国公立）★★", v1: "-", v1u: "", v2: "3.55 ", v3: "-", v4: "-", v4u: "" },
  { i: "111905", n: "学校給食（私立）★", v1: "-", v1u: "", v2: "3.59 ", v3: "-", v4: "-", v4u: "" },
  { i: "111909", n: "その他の食料品", v1: "1.76 ", v1u: "t", v2: "5.42 ", v3: "4.21 ", v4: "0.2807 ", v4u: "t" },
  { i: "112101", n: "清酒", v1: "-", v1u: "", v2: "2.80 ", v3: "2.24 ", v4: "-", v4u: "" },
  { i: "112102", n: "ビール", v1: "-", v1u: "", v2: "1.83 ", v3: "1.60 ", v4: "-", v4u: "" },
  { i: "112103", n: "ウィスキー類", v1: "-", v1u: "", v2: "2.16 ", v3: "1.85 ", v4: "-", v4u: "" },
  { i: "112109", n: "その他の酒類", v1: "-", v1u: "", v2: "2.60 ", v3: "2.12 ", v4: "-", v4u: "" },
  { i: "112901", n: "茶・コーヒー", v1: "9.35 ", v1u: "t", v2: "4.14 ", v3: "3.52 ", v4: "2.075 ", v4u: "t" },
  { i: "112902", n: "清涼飲料", v1: "0.760 ", v1u: "kl", v2: "3.20 ", v3: "2.63 ", v4: "0.2007 ", v4u: "kl" },
  { i: "112903", n: "製氷", v1: "0.118 ", v1u: "t", v2: "4.95 ", v3: "4.08 ", v4: "0.02183 ", v4u: "t" },
  { i: "113101", n: "飼料", v1: "0.445 ", v1u: "t", v2: "9.45 ", v3: "7.76 ", v4: "0.04623 ", v4u: "t" },
  { i: "113102", n: "有機質肥料（除別掲）", v1: "0.149 ", v1u: "t", v2: "5.22 ", v3: "4.15 ", v4: "0.02780 ", v4u: "t" },
  { i: "114101", n: "たばこ", v1: "-", v1u: "", v2: "0.89 ", v3: "0.89 ", v4: "-", v4u: "" },
  { i: "151101", n: "紡績糸", v1: "4.00 ", v1u: "t", v2: "6.35 ", v3: "5.30 ", v4: "0.6014 ", v4u: "t" },
  { i: "151201", n: "綿・スフ織物（含合繊短繊維織物）", v1: "1.54 ", v1u: "千m2", v2: "7.36 ", v3: "6.14 ", v4: "0.2002 ", v4u: "千m2" },
  { i: "151202", n: "絹・人絹織物（含合繊長繊維織物）", v1: "3.27 ", v1u: "千m2", v2: "6.92 ", v3: "5.57 ", v4: "0.4438 ", v4u: "千m2" },
  { i: "151203", n: "毛織物・麻織物・その他の織物", v1: "7.43 ", v1u: "千m2", v2: "6.66 ", v3: "5.46 ", v4: "1.050 ", v4u: "千m2" },
  { i: "151301", n: "ニット生地", v1: "4.77 ", v1u: "t", v2: "5.33 ", v3: "4.97 ", v4: "0.8792 ", v4u: "t" },
  { i: "151401", n: "染色整理", v1: "-", v1u: "", v2: "9.47 ", v3: "9.47 ", v4: "-", v4u: "" },
  { i: "151901", n: "綱・網", v1: "4.22 ", v1u: "t", v2: "5.54 ", v3: "4.50 ", v4: "0.6946 ", v4u: "t" },
  { i: "151902", n: "じゅうたん・床敷物", v1: "5.86 ", v1u: "千m2", v2: "6.06 ", v3: "4.78 ", v4: "0.8909 ", v4u: "千m2" },
  { i: "151903", n: "繊維製衛生材料", v1: "-", v1u: "", v2: "4.18 ", v3: "3.55 ", v4: "-", v4u: "" },
  { i: "151909", n: "その他の繊維工業製品", v1: "3.19 ", v1u: "t", v2: "5.68 ", v3: "5.13 ", v4: "0.5453 ", v4u: "t" },
  { i: "152101", n: "織物製衣服", v1: "0.01198 ", v1u: "着", v2: "4.01 ", v3: "3.23 ", v4: "0.002451 ", v4u: "着" },
  { i: "152102", n: "ニット製衣服", v1: "0.0523 ", v1u: "デカ", v2: "4.64 ", v3: "3.46 ", v4: "0.009127 ", v4u: "デカ" },
  { i: "152209", n: "その他の衣服・身の回り品", v1: "0.681 ", v1u: "千点", v2: "4.58 ", v3: "3.35 ", v4: "0.1199 ", v4u: "千点" },
  { i: "152901", n: "寝具", v1: "-", v1u: "", v2: "3.91 ", v3: "3.13 ", v4: "-", v4u: "" },
  { i: "152909", n: "その他の繊維既製品", v1: "-", v1u: "", v2: "3.87 ", v3: "3.08 ", v4: "-", v4u: "" },
  { i: "161101", n: "製材", v1: "137.6 ", v1u: "千m3", v2: "3.74 ", v3: "3.08 ", v4: "34.10 ", v4u: "千m3" },
  { i: "161102", n: "合板", v1: "0.563 ", v1u: "m3", v2: "4.21 ", v3: "3.46 ", v4: "0.1252 ", v4u: "m3" },
  { i: "161103", n: "木材チップ", v1: "23.1 ", v1u: "千m3", v2: "2.64 ", v3: "2.24 ", v4: "7.884 ", v4u: "千m3" },
  { i: "161909", n: "その他の木製品", v1: "-", v1u: "", v2: "2.86 ", v3: "2.40 ", v4: "-", v4u: "" },
  { i: "171101", n: "木製家具・装備品", v1: "-", v1u: "", v2: "3.16 ", v3: "2.51 ", v4: "-", v4u: "" },
  { i: "171102", n: "木製建具", v1: "-", v1u: "", v2: "3.12 ", v3: "2.37 ", v4: "-", v4u: "" },
  { i: "171103", n: "金属製家具・装備品", v1: "-", v1u: "", v2: "5.16 ", v3: "3.61 ", v4: "-", v4u: "" },
  { i: "181101", n: "パルプ", v1: "1.019 ", v1u: "t", v2: "19.40 ", v3: "16.33 ", v4: "0.05190 ", v4u: "t" },
  { i: "181201", n: "洋紙・和紙", v1: "1.83 ", v1u: "t", v2: "15.45 ", v3: "11.12 ", v4: "0.1140 ", v4u: "t" },
  { i: "181202", n: "板紙", v1: "0.801 ", v1u: "t", v2: "13.83 ", v3: "9.99 ", v4: "0.05576 ", v4u: "t" },
  { i: "181301", n: "段ボール", v1: "0.382 ", v1u: "千m2", v2: "7.82 ", v3: "6.32 ", v4: "0.04742 ", v4u: "千m2" },
  { i: "181302", n: "塗工紙・建設用加工紙", v1: "-", v1u: "", v2: "6.05 ", v3: "5.04 ", v4: "-", v4u: "" },
  { i: "182101", n: "段ボール箱", v1: "-", v1u: "", v2: "3.83 ", v3: "3.22 ", v4: "-", v4u: "" },
  { i: "182109", n: "その他の紙製容器", v1: "0.234 ", v1u: "千袋", v2: "4.84 ", v3: "3.97 ", v4: "0.04604 ", v4u: "千袋" },
  { i: "182901", n: "紙製衛生材料・用品", v1: "-", v1u: "", v2: "5.11 ", v3: "3.95 ", v4: "-", v4u: "" },
  { i: "182909", n: "その他のパルプ・紙・紙加工品", v1: "0.00148 ", v1u: "m2", v2: "4.62 ", v3: "3.79 ", v4: "0.00030 ", v4u: "m2" },
  { i: "191101", n: "印刷・製版・製本", v1: "-", v1u: "", v2: "3.26 ", v3: "3.04 ", v4: "-", v4u: "" },
  { i: "201101", n: "化学肥料", v1: "0.719 ", v1u: "t", v2: "17.36 ", v3: "13.08 ", v4: "0.04017 ", v4u: "t" },
  { i: "202101", n: "ソーダ工業製品", v1: "0.830 ", v1u: "t", v2: "21.23 ", v3: "17.93 ", v4: "0.03865 ", v4u: "t" },
  { i: "202901", n: "無機顔料", v1: "1.86 ", v1u: "t", v2: "9.75 ", v3: "8.00 ", v4: "0.1837 ", v4u: "t" },
  { i: "202902", n: "圧縮ガス・液化ガス", v1: "0.107 ", v1u: "千m3", v2: "11.38 ", v3: "9.31 ", v4: "0.009211 ", v4u: "千m3" },
  { i: "202903", n: "塩", v1: "-", v1u: "", v2: "14.27 ", v3: "10.51 ", v4: "-", v4u: "" },
  { i: "202909", n: "その他の無機化学工業製品", v1: "0.350 ", v1u: "t", v2: "13.73 ", v3: "11.44 ", v4: "0.02507 ", v4u: "t" },
  { i: "203101", n: "石油化学基礎製品", v1: "0.863 ", v1u: "t", v2: "10.45 ", v3: "9.98 ", v4: "0.08246 ", v4u: "t" },
  { i: "203102", n: "石油化学系芳香族製品", v1: "0.526 ", v1u: "t", v2: "8.95 ", v3: "8.39 ", v4: "0.05848 ", v4u: "t" },
  { i: "203201", n: "脂肪族中間物", v1: "1.65 ", v1u: "t", v2: "14.96 ", v3: "12.96 ", v4: "0.1087 ", v4u: "t" },
  { i: "203202", n: "環式中間物", v1: "1.49 ", v1u: "t", v2: "10.27 ", v3: "9.84 ", v4: "0.1446 ", v4u: "t" },
  { i: "203301", n: "合成ゴム", v1: "5.91 ", v1u: "t", v2: "18.21 ", v3: "15.87 ", v4: "0.3211 ", v4u: "t" },
  { i: "203901", n: "メタン誘導品", v1: "1.26 ", v1u: "t", v2: "15.33 ", v3: "12.97 ", v4: "0.08071 ", v4u: "t" },
  { i: "203902", n: "油脂加工製品", v1: "0.775 ", v1u: "t", v2: "6.21 ", v3: "5.02 ", v4: "0.1166 ", v4u: "t" },
  { i: "203903", n: "可塑剤", v1: "1.61 ", v1u: "t", v2: "10.18 ", v3: "8.70 ", v4: "0.1547 ", v4u: "t" },
  { i: "203904", n: "合成染料", v1: "14.2 ", v1u: "t", v2: "13.63 ", v3: "12.87 ", v4: "1.036 ", v4u: "t" },
  { i: "203909", n: "その他の有機化学工業製品", v1: "0.742 ", v1u: "t", v2: "9.70 ", v3: "8.27 ", v4: "0.07456 ", v4u: "t" },
  { i: "204101", n: "熱硬化性樹脂", v1: "3.66 ", v1u: "t", v2: "7.98 ", v3: "6.86 ", v4: "0.4445 ", v4u: "t" },
  { i: "204102", n: "熱可塑性樹脂", v1: "1.29 ", v1u: "t", v2: "8.98 ", v3: "7.72 ", v4: "0.1398 ", v4u: "t" },
  { i: "204103", n: "高機能性樹脂", v1: "2.45 ", v1u: "t", v2: "7.88 ", v3: "6.78 ", v4: "0.3012 ", v4u: "t" },
  { i: "204109", n: "その他の合成樹脂", v1: "2.80 ", v1u: "t", v2: "8.96 ", v3: "7.67 ", v4: "0.3043 ", v4u: "t" },
  { i: "205101", n: "レーヨン・アセテート", v1: "7.76 ", v1u: "t", v2: "16.63 ", v3: "11.73 ", v4: "0.4464 ", v4u: "t" },
  { i: "205102", n: "合成繊維", v1: "4.04 ", v1u: "t", v2: "11.68 ", v3: "9.75 ", v4: "0.3389 ", v4u: "t" },
  { i: "206101", n: "医薬品", v1: "-", v1u: "", v2: "3.02 ", v3: "2.56 ", v4: "-", v4u: "" },
  { i: "207101", n: "石けん・合成洗剤・界面活性剤", v1: "1.60 ", v1u: "t", v2: "5.46 ", v3: "4.65 ", v4: "0.2755 ", v4u: "t" },
  { i: "207102", n: "化粧品・歯磨", v1: "0.0195 ", v1u: "kg", v2: "4.32 ", v3: "3.50 ", v4: "0.004000 ", v4u: "kg" },
  { i: "207201", n: "塗料", v1: "2.30 ", v1u: "t", v2: "6.28 ", v3: "4.99 ", v4: "0.3381 ", v4u: "t" },
  { i: "207202", n: "印刷インキ", v1: "3.52 ", v1u: "t", v2: "5.64 ", v3: "4.88 ", v4: "0.5988 ", v4u: "t" },
  { i: "207301", n: "写真感光材料", v1: "0.00235 ", v1u: "m2", v2: "6.55 ", v3: "5.45 ", v4: "0.00034 ", v4u: "m2" },
  { i: "207401", n: "農薬", v1: "11.32 ", v1u: "t", v2: "7.56 ", v3: "5.86 ", v4: "1.332 ", v4u: "t" },
  { i: "207901", n: "ゼラチン・接着剤", v1: "0.00223 ", v1u: "kg", v2: "6.15 ", v3: "5.14 ", v4: "0.00034 ", v4u: "kg" },
  { i: "207909", n: "その他の化学最終製品", v1: "5.77 ", v1u: "t", v2: "7.41 ", v3: "6.36 ", v4: "0.7533 ", v4u: "t" },
  { i: "211101", n: "石油製品", v1: "0.573 ", v1u: "kl", v2: "8.60 ", v3: "7.13 ", v4: "0.06360 ", v4u: "kl" },
  { i: "212101", n: "石炭製品", v1: "0.321 ", v1u: "t", v2: "21.54 ", v3: "19.54 ", v4: "0.01482 ", v4u: "t" },
  { i: "212102", n: "舗装材料", v1: "-", v1u: "", v2: "4.25 ", v3: "3.48 ", v4: "-", v4u: "" },
  { i: "221101", n: "プラスチック製品", v1: "1.95 ", v1u: "t", v2: "4.71 ", v3: "4.00 ", v4: "0.3878 ", v4u: "t" },
  { i: "231101", n: "タイヤ・チューブ", v1: "-", v1u: "", v2: "7.14 ", v3: "6.11 ", v4: "-", v4u: "" },
  { i: "231901", n: "ゴム製履物", v1: "4.94 ", v1u: "千足", v2: "3.36 ", v3: "2.72 ", v4: "1.155 ", v4u: "千足" },
  { i: "231902", n: "プラスチック製履物", v1: "5.05 ", v1u: "千足", v2: "4.35 ", v3: "3.37 ", v4: "0.9444 ", v4u: "千足" },
  { i: "231909", n: "その他のゴム製品", v1: "1.64 ", v1u: "t", v2: "4.56 ", v3: "3.96 ", v4: "0.3416 ", v4u: "t" },
  { i: "241101", n: "革製履物", v1: "0.0180 ", v1u: "足", v2: "3.14 ", v3: "2.57 ", v4: "0.004558 ", v4u: "足" },
  { i: "241201", n: "製革・毛皮", v1: "0.0484 ", v1u: "枚", v2: "6.73 ", v3: "5.36 ", v4: "0.006738 ", v4u: "枚" },
  { i: "241202", n: "かばん・袋物・その他の革製品", v1: "0.0127 ", v1u: "個", v2: "3.20 ", v3: "2.74 ", v4: "0.003437 ", v4u: "個" },
  { i: "251101", n: "板ガラス・安全ガラス", v1: "0.0322 ", v1u: "m2", v2: "5.81 ", v3: "4.94 ", v4: "0.005338 ", v4u: "m2" },
  { i: "251201", n: "ガラス繊維・同製品", v1: "0.00269 ", v1u: "kg", v2: "9.64 ", v3: "8.00 ", v4: "0.00027 ", v4u: "kg" },
  { i: "251909", n: "その他のガラス製品", v1: "2.46 ", v1u: "t", v2: "6.16 ", v3: "5.39 ", v4: "0.3894 ", v4u: "t" },
  { i: "252101", n: "セメント", v1: "0.758 ", v1u: "t", v2: "137.71 ", v3: "101.62 ", v4: "0.005491 ", v4u: "t" },
  { i: "252201", n: "生コンクリート", v1: "0.316 ", v1u: "m3", v2: "27.30 ", v3: "19.53 ", v4: "0.01133 ", v4u: "m3" },
  { i: "252301", n: "セメント製品", v1: "0.232 ", v1u: "t", v2: "10.45 ", v3: "7.60 ", v4: "0.02092 ", v4u: "t" },
  { i: "253101", n: "陶磁器", v1: "-", v1u: "", v2: "7.31 ", v3: "5.78 ", v4: "-", v4u: "" },
  { i: "259901", n: "耐火物", v1: "1.346 ", v1u: "t", v2: "10.24 ", v3: "7.92 ", v4: "0.1264 ", v4u: "t" },
  { i: "259902", n: "その他の建設用土石製品", v1: "0.257 ", v1u: "t", v2: "10.87 ", v3: "7.71 ", v4: "0.02222 ", v4u: "t" },
  { i: "259903", n: "炭素・黒鉛製品", v1: "-", v1u: "", v2: "8.45 ", v3: "7.21 ", v4: "-", v4u: "" },
  { i: "259904", n: "研磨材", v1: "6.92 ", v1u: "t", v2: "6.25 ", v3: "4.86 ", v4: "1.051 ", v4u: "t" },
  { i: "259909", n: "その他の窯業・土石製品", v1: "0.112 ", v1u: "t", v2: "7.15 ", v3: "5.99 ", v4: "0.01508 ", v4u: "t" },
  { i: "261101", n: "銑鉄", v1: "1.88 ", v1u: "t", v2: "72.59 ", v3: "67.06 ", v4: "0.02592 ", v4u: "t" },
  { i: "261102", n: "フェロアロイ", v1: "5.13 ", v1u: "t", v2: "19.19 ", v3: "17.81 ", v4: "0.2665 ", v4u: "t" },
  { i: "261103", n: "粗鋼（転炉）", v1: "2.04 ", v1u: "t", v2: "45.50 ", v3: "43.74 ", v4: "0.04477 ", v4u: "t" },
  { i: "261104", n: "粗鋼（電気炉）", v1: "0.711 ", v1u: "t", v2: "14.33 ", v3: "13.78 ", v4: "0.04944 ", v4u: "t" },
  { i: "2612011", n: "鉄屑", v1: "-", v1u: "", v2: "0.00 ", v3: "-", v4: "-", v4u: "" },
  { i: "262101", n: "熱間圧延鋼材", v1: "1.90 ", v1u: "t", v2: "26.80 ", v3: "23.18 ", v4: "0.07028 ", v4u: "t" },
  { i: "262201", n: "鋼管", v1: "2.40 ", v1u: "t", v2: "17.96 ", v3: "15.06 ", v4: "0.1320 ", v4u: "t" },
  { i: "262301", n: "冷間仕上鋼材", v1: "2.13 ", v1u: "t", v2: "20.44 ", v3: "17.70 ", v4: "0.1034 ", v4u: "t" },
  { i: "262302", n: "めっき鋼材", v1: "1.42 ", v1u: "t", v2: "15.50 ", v3: "12.86 ", v4: "0.09021 ", v4u: "t" },
  { i: "263101", n: "鋳鍛鋼", v1: "4.66 ", v1u: "t", v2: "11.94 ", v3: "11.18 ", v4: "0.3887 ", v4u: "t" },
  { i: "263102", n: "鋳鉄管", v1: "3.40 ", v1u: "t", v2: "12.80 ", v3: "11.80 ", v4: "0.2644 ", v4u: "t" },
  { i: "263103", n: "鋳鉄品及び鍛工品（鉄）", v1: "3.49 ", v1u: "t", v2: "16.97 ", v3: "15.80 ", v4: "0.2053 ", v4u: "t" },
  { i: "264901", n: "鉄鋼シャースリット業", v1: "-", v1u: "", v2: "14.61 ", v3: "13.46 ", v4: "-", v4u: "" },
  { i: "264909", n: "その他の鉄鋼製品", v1: "-", v1u: "", v2: "9.94 ", v3: "9.29 ", v4: "-", v4u: "" },
  { i: "271101", n: "銅", v1: "4.49 ", v1u: "t", v2: "10.17 ", v3: "9.50 ", v4: "0.4390 ", v4u: "t" },
  { i: "271102", n: "鉛・亜鉛（含再生）", v1: "1.48 ", v1u: "t", v2: "9.30 ", v3: "8.77 ", v4: "0.1585 ", v4u: "t" },
  { i: "271103", n: "アルミニウム（含再生）", v1: "1.49 ", v1u: "t", v2: "7.50 ", v3: "6.41 ", v4: "0.1939 ", v4u: "t" },
  { i: "271109", n: "その他の非鉄金属地金", v1: "13.5 ", v1u: "t", v2: "8.26 ", v3: "7.45 ", v4: "1.612 ", v4u: "t" },
  { i: "2712011", n: "非鉄金属屑", v1: "-", v1u: "", v2: "0.00 ", v3: "-", v4: "-", v4u: "" },
  { i: "272101", n: "電線・ケーブル", v1: "8.70 ", v1u: "導体t", v2: "6.28 ", v3: "5.68 ", v4: "1.354 ", v4u: "導体t" },
  { i: "272102", n: "光ファイバケーブル", v1: "0.0556 ", v1u: "Kmcore", v2: "6.27 ", v3: "5.67 ", v4: "0.008693 ", v4u: "Kmcore" },
  { i: "272201", n: "伸銅品", v1: "4.19 ", v1u: "t", v2: "7.29 ", v3: "6.03 ", v4: "0.5490 ", v4u: "t" },
  { i: "272202", n: "アルミ圧延製品", v1: "5.33 ", v1u: "t", v2: "11.01 ", v3: "9.42 ", v4: "0.4745 ", v4u: "t" },
  { i: "272203", n: "非鉄金属素形材", v1: "0.00529 ", v1u: "kg", v2: "8.25 ", v3: "7.26 ", v4: "0.00063 ", v4u: "kg" },
  { i: "272204", n: "核燃料", v1: "-", v1u: "", v2: "4.02 ", v3: "-", v4: "-", v4u: "" },
  { i: "272209", n: "その他の非鉄金属製品", v1: "38.1 ", v1u: "t", v2: "10.00 ", v3: "8.29 ", v4: "3.695 ", v4u: "t" },
  { i: "281101", n: "建設用金属製品", v1: "1.99 ", v1u: "t", v2: "8.74 ", v3: "7.34 ", v4: "0.2262 ", v4u: "t" },
  { i: "281201", n: "建築用金属製品", v1: "-", v1u: "", v2: "6.32 ", v3: "5.26 ", v4: "-", v4u: "" },
  { i: "289101", n: "ガス・石油機器及び暖厨房機器", v1: "0.261 ", v1u: "台", v2: "6.87 ", v3: "5.89 ", v4: "0.03678 ", v4u: "台" },
  { i: "289901", n: "ボルト・ナット・リベット及びスプリング", v1: "2.73 ", v1u: "t", v2: "7.26 ", v3: "5.61 ", v4: "0.3491 ", v4u: "t" },
  { i: "289902", n: "金属製容器及び製缶板金製品", v1: "2.00 ", v1u: "t", v2: "6.50 ", v3: "5.76 ", v4: "0.3010 ", v4u: "t" },
  { i: "289903", n: "配管工事付属品・粉末や金製品・道具類", v1: "-", v1u: "", v2: "5.50 ", v3: "4.55 ", v4: "-", v4u: "" },
  { i: "289909", n: "その他の金属製品", v1: "1.87 ", v1u: "t", v2: "5.83 ", v3: "5.12 ", v4: "0.3095 ", v4u: "t" },
  { i: "301101", n: "ボイラ", v1: "109.8 ", v1u: "台", v2: "3.22 ", v3: "2.86 ", v4: "31.19 ", v4u: "台" },
  { i: "301102", n: "タービン", v1: "2,040", v1u: "台", v2: "4.28 ", v3: "3.83 ", v4: "453.5 ", v4u: "台" },
  { i: "301103", n: "原動機", v1: "0.436 ", v1u: "台", v2: "5.11 ", v3: "4.14 ", v4: "0.07751 ", v4u: "台" },
  { i: "301201", n: "運搬機械", v1: "31.8 ", v1u: "台", v2: "4.59 ", v3: "4.19 ", v4: "6.686 ", v4u: "台" },
  { i: "301301", n: "冷凍機・温湿調整装置", v1: "0.203 ", v1u: "台", v2: "7.19 ", v3: "6.46 ", v4: "0.02758 ", v4u: "台" },
  { i: "301901", n: "ポンプ及び圧縮機", v1: "0.388 ", v1u: "台", v2: "4.96 ", v3: "4.33 ", v4: "0.07409 ", v4u: "台" },
  { i: "301902", n: "機械工具", v1: "-", v1u: "", v2: "4.65 ", v3: "3.91 ", v4: "-", v4u: "" },
  { i: "301909", n: "その他の一般産業機械及び装置", v1: "0.189 ", v1u: "台", v2: "4.73 ", v3: "4.33 ", v4: "0.03871 ", v4u: "台" },
  { i: "302101", n: "建設・鉱山機械", v1: "20.4 ", v1u: "台", v2: "4.53 ", v3: "4.08 ", v4: "4.295 ", v4u: "台" },
  { i: "302201", n: "化学機械", v1: "-", v1u: "", v2: "3.44 ", v3: "3.27 ", v4: "-", v4u: "" },
  { i: "302301", n: "産業用ロボット", v1: "-", v1u: "", v2: "3.84 ", v3: "3.66 ", v4: "-", v4u: "" },
  { i: "302401", n: "金属工作機械", v1: "46.1 ", v1u: "台", v2: "3.70 ", v3: "3.48 ", v4: "12.05 ", v4u: "台" },
  { i: "302402", n: "金属加工機械", v1: "44.1 ", v1u: "台", v2: "4.42 ", v3: "4.12 ", v4: "9.713 ", v4u: "台" },
  { i: "302901", n: "農業用機械", v1: "3.39 ", v1u: "台", v2: "4.29 ", v3: "3.88 ", v4: "0.7526 ", v4u: "台" },
  { i: "302902", n: "繊維機械", v1: "0.320 ", v1u: "台", v2: "4.25 ", v3: "3.68 ", v4: "0.07002 ", v4u: "台" },
  { i: "302903", n: "食品機械・同装置", v1: "4.40 ", v1u: "台", v2: "5.04 ", v3: "4.63 ", v4: "0.8486 ", v4u: "台" },
  { i: "302904", n: "半導体製造装置", v1: "213 ", v1u: "台", v2: "3.28 ", v3: "2.96 ", v4: "60.32 ", v4u: "台" },
  { i: "302905", n: "真空装置・真空機器", v1: "1.62 ", v1u: "台", v2: "4.43 ", v3: "3.95 ", v4: "0.3478 ", v4u: "台" },
  { i: "302909", n: "その他の特殊産業用機械", v1: "28.9 ", v1u: "台", v2: "3.99 ", v3: "3.82 ", v4: "7.108 ", v4u: "台" },
  { i: "303101", n: "金型", v1: "-", v1u: "", v2: "4.37 ", v3: "3.97 ", v4: "-", v4u: "" },
  { i: "303102", n: "ベアリング", v1: "-", v1u: "", v2: "6.87 ", v3: "6.00 ", v4: "-", v4u: "" },
  { i: "303109", n: "その他の一般機械器具及び部品", v1: "5.46 ", v1u: "個", v2: "4.61 ", v3: "4.10 ", v4: "1.125 ", v4u: "個" },
  { i: "311101", n: "複写機", v1: "1.52 ", v1u: "台", v2: "3.82 ", v3: "3.31 ", v4: "0.3664 ", v4u: "台" },
  { i: "311109", n: "その他の事務用機械", v1: "-", v1u: "", v2: "3.20 ", v3: "2.72 ", v4: "-", v4u: "" },
  { i: "311201", n: "サービス用機器", v1: "1.220 ", v1u: "台", v2: "3.87 ", v3: "3.46 ", v4: "0.2973 ", v4u: "台" },
  { i: "321101", n: "回転電気機械", v1: "0.0915 ", v1u: "台", v2: "5.37 ", v3: "4.61 ", v4: "0.01611 ", v4u: "台" },
  { i: "321102", n: "変圧器・変成器", v1: "0.394 ", v1u: "台", v2: "5.26 ", v3: "4.72 ", v4: "0.07219 ", v4u: "台" },
  { i: "321103", n: "開閉制御装置及び配電盤", v1: "-", v1u: "", v2: "4.46 ", v3: "4.08 ", v4: "-", v4u: "" },
  { i: "321104", n: "配線器具", v1: "0.391 ", v1u: "千個", v2: "3.77 ", v3: "3.39 ", v4: "0.09838 ", v4u: "千個" },
  { i: "321105", n: "内燃機関電装品", v1: "0.0373 ", v1u: "台", v2: "3.88 ", v3: "3.42 ", v4: "0.008966 ", v4u: "台" },
  { i: "321109", n: "その他の産業用電気機器", v1: "2.78 ", v1u: "台", v2: "4.01 ", v3: "3.61 ", v4: "0.6590 ", v4u: "台" },
  { i: "322101", n: "電子応用装置", v1: "0.414 ", v1u: "台", v2: "3.01 ", v3: "2.71 ", v4: "0.1259 ", v4u: "台" },
  { i: "323101", n: "電気計測器", v1: "-", v1u: "", v2: "2.74 ", v3: "2.53 ", v4: "-", v4u: "" },
  { i: "324101", n: "電球類", v1: "1.111 ", v1u: "千個", v2: "3.22 ", v3: "2.67 ", v4: "0.1934 ", v4u: "千個" },
  { i: "324102", n: "電気照明器具", v1: "11.28 ", v1u: "千個", v2: "3.71 ", v3: "3.14 ", v4: "2.668 ", v4u: "千個" },
  { i: "324103", n: "電池", v1: "0.868 ", v1u: "千個", v2: "5.82 ", v3: "4.15 ", v4: "0.1153 ", v4u: "千個" },
  { i: "324109", n: "その他の電気機械器具", v1: "-", v1u: "", v2: "5.56 ", v3: "5.03 ", v4: "-", v4u: "" },
  { i: "325101", n: "民生用エアコンディショナ", v1: "0.307 ", v1u: "台", v2: "4.12 ", v3: "3.43 ", v4: "0.06685 ", v4u: "台" },
  { i: "325102", n: "民生用電気機器（除エアコン）", v1: "0.1328 ", v1u: "台", v2: "3.85 ", v3: "3.15 ", v4: "0.03002 ", v4u: "台" },
  { i: "331101", n: "ビデオ機器", v1: "0.1231 ", v1u: "台", v2: "3.83 ", v3: "3.02 ", v4: "0.02679 ", v4u: "台" },
  { i: "331102", n: "電気音響機器", v1: "-", v1u: "", v2: "3.50 ", v3: "3.20 ", v4: "-", v4u: "" },
  { i: "331103", n: "ラジオ・テレビ受信機", v1: "0.499 ", v1u: "台", v2: "3.53 ", v3: "3.45 ", v4: "0.1400 ", v4u: "台" },
  { i: "332101", n: "有線電気通信機器", v1: "0.159 ", v1u: "台", v2: "3.29 ", v3: "2.81 ", v4: "0.04225 ", v4u: "台" },
  { i: "332102", n: "携帯電話機", v1: "0.1699 ", v1u: "台", v2: "3.32 ", v3: "2.50 ", v4: "0.03448 ", v4u: "台" },
  { i: "332103", n: "無線電気通信機器（除携帯電話機）", v1: "-", v1u: "", v2: "3.42 ", v3: "3.12 ", v4: "-", v4u: "" },
  { i: "332109", n: "その他の電気通信機器", v1: "-", v1u: "", v2: "2.97 ", v3: "2.74 ", v4: "-", v4u: "" },
  { i: "333101", n: "パーソナルコンピュータ", v1: "0.452 ", v1u: "台", v2: "3.50 ", v3: "3.37 ", v4: "0.1262 ", v4u: "台" },
  { i: "333102", n: "電子計算機本体（除パソコン）", v1: "6.75 ", v1u: "台", v2: "2.93 ", v3: "2.78 ", v4: "2.209 ", v4u: "台" },
  { i: "333103", n: "電子計算機付属装置", v1: "0.251 ", v1u: "台", v2: "3.23 ", v3: "3.09 ", v4: "0.07546 ", v4u: "台" },
  { i: "341101", n: "半導体素子", v1: "0.122 ", v1u: "千個", v2: "8.69 ", v3: "7.88 ", v4: "0.01377 ", v4u: "千個" },
  { i: "341102", n: "集積回路", v1: "0.354 ", v1u: "千個", v2: "4.28 ", v3: "3.95 ", v4: "0.08007 ", v4u: "千個" },
  { i: "342101", n: "電子管", v1: "0.0385 ", v1u: "本", v2: "5.93 ", v3: "5.30 ", v4: "0.006286 ", v4u: "本" },
  { i: "342102", n: "液晶素子", v1: "13.4 ", v1u: "千個", v2: "4.49 ", v3: "4.08 ", v4: "2.861 ", v4u: "千個" },
  { i: "342103", n: "磁気テープ・磁気ディスク", v1: "-", v1u: "", v2: "5.29 ", v3: "3.99 ", v4: "-", v4u: "" },
  { i: "342109", n: "その他の電子部品", v1: "-", v1u: "", v2: "4.08 ", v3: "3.83 ", v4: "-", v4u: "" },
  { i: "351101", n: "乗用車", v1: "", v1u: "台", v2: "4.44 ", v3: "-", v4: "1.630 ", v4u: "台" },
  { i: "352101", n: "トラック・バス・その他の自動車", v1: "7.43 ", v1u: "台", v2: "4.53 ", v3: "3.63 ", v4: "1.459 ", v4u: "台" },
  { i: "353101", n: "二輪自動車", v1: "", v1u: "台", v2: "3.97 ", v3: "-", v4: "0.3775 ", v4u: "台" },
  { i: "354101", n: "自動車車体", v1: "7.80 ", v1u: "台", v2: "6.05 ", v3: "5.93 ", v4: "1.287 ", v4u: "台" },
  { i: "354102", n: "自動車用内燃機関・同部分品", v1: "0.918 ", v1u: "台", v2: "4.96 ", v3: "4.69 ", v4: "0.1824 ", v4u: "台" },
  { i: "354103", n: "自動車部品", v1: "-", v1u: "", v2: "4.72 ", v3: "4.52 ", v4: "-", v4u: "" },
  { i: "361101", n: "鋼船", v1: "-", v1u: "", v2: "8.09 ", v3: "6.96 ", v4: "-", v4u: "" },
  { i: "361102", n: "その他の船舶", v1: "-", v1u: "", v2: "3.58 ", v3: "3.27 ", v4: "-", v4u: "" },
  { i: "361103", n: "舶用内燃機関", v1: "58.6 ", v1u: "台", v2: "6.29 ", v3: "5.91 ", v4: "9.203 ", v4u: "台" },
  { i: "361110", n: "船舶修理", v1: "-", v1u: "", v2: "4.43 ", v3: "4.43 ", v4: "-", v4u: "" },
  { i: "362101", n: "鉄道車両", v1: "-", v1u: "", v2: "5.45 ", v3: "5.25 ", v4: "-", v4u: "" },
  { i: "362110", n: "鉄道車両修理", v1: "-", v1u: "", v2: "7.82 ", v3: "7.82 ", v4: "-", v4u: "" },
  { i: "362201", n: "航空機", v1: "2,994", v1u: "機", v2: "3.16 ", v3: "2.95 ", v4: "904.4 ", v4u: "機" },
  { i: "362210", n: "航空機修理", v1: "-", v1u: "", v2: "2.86 ", v3: "2.86 ", v4: "-", v4u: "" },
  { i: "362901", n: "自転車", v1: "0.1224 ", v1u: "台", v2: "6.54 ", v3: "6.47 ", v4: "0.01869 ", v4u: "台" },
  { i: "362909", n: "その他の輸送機械", v1: "11.9 ", v1u: "台", v2: "4.95 ", v3: "4.56 ", v4: "2.329 ", v4u: "台" },
  { i: "371101", n: "カメラ", v1: "1.199 ", v1u: "台", v2: "3.00 ", v3: "2.72 ", v4: "0.3144 ", v4u: "台" },
  { i: "371109", n: "その他の光学機械", v1: "-", v1u: "", v2: "3.75 ", v3: "3.22 ", v4: "-", v4u: "" },
  { i: "371201", n: "時計", v1: "0.00283 ", v1u: "個", v2: "3.58 ", v3: "3.16 ", v4: "0.00069 ", v4u: "個" },
  { i: "371901", n: "理化学機械器具", v1: "-", v1u: "", v2: "2.80 ", v3: "2.42 ", v4: "-", v4u: "" },
  { i: "371902", n: "分析器・試験機・計量器・測定器", v1: "-", v1u: "", v2: "2.81 ", v3: "2.44 ", v4: "-", v4u: "" },
  { i: "371903", n: "医療用機械器具", v1: "-", v1u: "", v2: "3.21 ", v3: "2.49 ", v4: "-", v4u: "" },
  { i: "391101", n: "がん具", v1: "-", v1u: "", v2: "3.88 ", v3: "2.72 ", v4: "-", v4u: "" },
  { i: "391102", n: "運動用品", v1: "-", v1u: "", v2: "4.33 ", v3: "3.31 ", v4: "-", v4u: "" },
  { i: "391901", n: "楽器", v1: "0.669 ", v1u: "個", v2: "3.11 ", v3: "2.46 ", v4: "0.1472 ", v4u: "個" },
  { i: "391902", n: "情報記録物", v1: "-", v1u: "", v2: "2.63 ", v3: "2.44 ", v4: "-", v4u: "" },
  { i: "391903", n: "筆記具・文具", v1: "0.160 ", v1u: "千本", v2: "3.20 ", v3: "2.67 ", v4: "0.04297 ", v4u: "千本" },
  { i: "391904", n: "身辺細貨品", v1: "0.622 ", v1u: "万本", v2: "4.30 ", v3: "3.09 ", v4: "0.07051 ", v4u: "万本" },
  { i: "391905", n: "畳・わら加工品", v1: "-", v1u: "", v2: "4.52 ", v3: "3.38 ", v4: "-", v4u: "" },
  { i: "391906", n: "武器", v1: "1.70 ", v1u: "個", v2: "3.87 ", v3: "3.74 ", v4: "0.4388 ", v4u: "個" },
  { i: "391909", n: "その他の製造工業製品", v1: "0.00733 ", v1u: "本", v2: "3.30 ", v3: "2.54 ", v4: "0.001531 ", v4u: "本" },
  { i: "392101", n: "再生資源回収・加工処理", v1: "-", v1u: "", v2: "3.49 ", v3: "3.49 ", v4: "-", v4u: "" },
  { i: "411101", n: "住宅建築（木造）", v1: "", v1u: "m2", v2: "2.98 ", v3: "-", v4: "0.1583 ", v4u: "m2" },
  { i: "411102", n: "住宅建築（非木造）", v1: "", v1u: "m2", v2: "4.09 ", v3: "-", v4: "0.1809 ", v4u: "m2" },
  { i: "411201", n: "非住宅建築（木造）", v1: "", v1u: "m2", v2: "3.17 ", v3: "-", v4: "0.1290 ", v4u: "m2" },
  { i: "411202", n: "非住宅建築（非木造）", v1: "", v1u: "m2", v2: "4.24 ", v3: "-", v4: "0.1461 ", v4u: "m2" },
  { i: "412101", n: "建設補修", v1: "-", v1u: "", v2: "3.73 ", v3: "3.73 ", v4: "-", v4u: "" },
  { i: "413101", n: "道路関係公共事業", v1: "-", v1u: "", v2: "4.64 ", v3: "-", v4: "-", v4u: "" },
  { i: "413102", n: "河川・下水道・その他の公共事業", v1: "-", v1u: "", v2: "4.45 ", v3: "-", v4: "-", v4u: "" },
  { i: "413103", n: "農林関係公共事業", v1: "-", v1u: "", v2: "5.22 ", v3: "-", v4: "-", v4u: "" },
  { i: "413201", n: "鉄道軌道建設", v1: "-", v1u: "", v2: "4.98 ", v3: "-", v4: "-", v4u: "" },
  { i: "413202", n: "電力施設建設", v1: "-", v1u: "", v2: "4.62 ", v3: "-", v4: "-", v4u: "" },
  { i: "413203", n: "電気通信施設建設", v1: "-", v1u: "", v2: "3.93 ", v3: "-", v4: "-", v4u: "" },
  { i: "413209", n: "その他の土木建設", v1: "-", v1u: "", v2: "4.44 ", v3: "-", v4: "-", v4u: "" },
  { i: "511101", n: "事業用電力", v1: "468", v1u: "百万kWh", v2: "29.08 ", v3: "29.08 ", v4: "16.08 ", v4u: "百万kWh" },
  { i: "511104", n: "自家発電", v1: "589", v1u: "百万kWh", v2: "68.82 ", v3: "68.82 ", v4: "8.557 ", v4u: "百万kWh" },
  { i: "512101", n: "都市ガス", v1: "0.497 ", v1u: "千m2", v2: "5.57 ", v3: "5.57 ", v4: "0.08929 ", v4u: "千m2" },
  { i: "512201", n: "熱供給業", v1: "0.099 ", v1u: "GJ", v2: "15.98 ", v3: "15.98 ", v4: "0.006176 ", v4u: "GJ" },
  { i: "521101", n: "上水道・簡易水道", v1: "-", v1u: "", v2: "1.50 ", v3: "1.50 ", v4: "-", v4u: "" },
  { i: "521102", n: "工業用水", v1: "-", v1u: "", v2: "1.63 ", v3: "1.63 ", v4: "-", v4u: "" },
  { i: "521103", n: "下水道★★", v1: "-", v1u: "", v2: "12.27 ", v3: "12.27 ", v4: "-", v4u: "" },
  { i: "521201", n: "廃棄物処理（公営）★★", v1: "-", v1u: "", v2: "16.37 ", v3: "16.37 ", v4: "-", v4u: "" },
  { i: "521202", n: "廃棄物処理（産業）", v1: "-", v1u: "", v2: "7.81 ", v3: "7.81 ", v4: "-", v4u: "" },
  { i: "611101", n: "卸売", v1: "-", v1u: "", v2: "1.24 ", v3: "1.24 ", v4: "-", v4u: "" },
  { i: "611201", n: "小売", v1: "-", v1u: "", v2: "2.28 ", v3: "-", v4: "-", v4u: "" },
  { i: "621101", n: "金融", v1: "-", v1u: "", v2: "0.69 ", v3: "0.69 ", v4: "-", v4u: "" },
  { i: "621201", n: "生命保険", v1: "-", v1u: "", v2: "0.86 ", v3: "-", v4: "-", v4u: "" },
  { i: "621202", n: "損害保険", v1: "-", v1u: "", v2: "0.73 ", v3: "0.73 ", v4: "-", v4u: "" },
  { i: "641101", n: "不動産仲介・管理業", v1: "-", v1u: "", v2: "1.15 ", v3: "1.15 ", v4: "-", v4u: "" },
  { i: "641102", n: "不動産賃貸業", v1: "-", v1u: "", v2: "1.07 ", v3: "1.07 ", v4: "-", v4u: "" },
  { i: "642101", n: "住宅賃貸料", v1: "-", v1u: "", v2: "0.57 ", v3: "-", v4: "-", v4u: "" },
  { i: "642201", n: "住宅賃貸料（帰属家賃）", v1: "-", v1u: "", v2: "0.25 ", v3: "-", v4: "-", v4u: "" },
  { i: "711101", n: "鉄道旅客輸送", v1: "-", v1u: "", v2: "3.02 ", v3: "3.02 ", v4: "-", v4u: "" },
  { i: "711201", n: "鉄道貨物輸送", v1: "-", v1u: "", v2: "4.90 ", v3: "4.90 ", v4: "-", v4u: "" },
  { i: "712101", n: "バス", v1: "-", v1u: "", v2: "3.28 ", v3: "3.28 ", v4: "-", v4u: "" },
  { i: "712102", n: "ハイヤー・タクシー", v1: "-", v1u: "", v2: "3.37 ", v3: "3.37 ", v4: "-", v4u: "" },
  { i: "712201", n: "道路貨物輸送（除自家輸送）", v1: "-", v1u: "", v2: "3.93 ", v3: "3.93 ", v4: "-", v4u: "" },
  { i: "713101", n: "自家輸送（旅客自動車）", v1: "-", v1u: "", v2: "12.21 ", v3: "12.21 ", v4: "-", v4u: "" },
  { i: "713201", n: "自家輸送（貨物自動車）", v1: "-", v1u: "", v2: "11.79 ", v3: "11.79 ", v4: "-", v4u: "" },
  { i: "714101", n: "外洋輸送", v1: "-", v1u: "", v2: "27.33 ", v3: "27.33 ", v4: "-", v4u: "" },
  { i: "714201", n: "沿海・内水面輸送", v1: "-", v1u: "", v2: "13.30 ", v3: "13.30 ", v4: "-", v4u: "" },
  { i: "714301", n: "港湾運送", v1: "-", v1u: "", v2: "1.74 ", v3: "1.74 ", v4: "-", v4u: "" },
  { i: "715101", n: "航空輸送", v1: "-", v1u: "", v2: "12.14 ", v3: "12.14 ", v4: "-", v4u: "" },
  { i: "716101", n: "貨物利用運送", v1: "-", v1u: "", v2: "1.56 ", v3: "1.56 ", v4: "-", v4u: "" },
  { i: "717101", n: "倉庫", v1: "-", v1u: "", v2: "2.33 ", v3: "2.33 ", v4: "-", v4u: "" },
  { i: "718101", n: "こん包", v1: "-", v1u: "", v2: "2.43 ", v3: "2.43 ", v4: "-", v4u: "" },
  { i: "718901", n: "道路輸送施設提供", v1: "-", v1u: "", v2: "1.38 ", v3: "1.38 ", v4: "-", v4u: "" },
  { i: "718902", n: "水運施設管理★★", v1: "-", v1u: "", v2: "1.95 ", v3: "1.95 ", v4: "-", v4u: "" },
  { i: "718903", n: "その他の水運付帯サービス", v1: "-", v1u: "", v2: "0.76 ", v3: "0.76 ", v4: "-", v4u: "" },
  { i: "718904", n: "航空施設管理（国公営）★★", v1: "-", v1u: "", v2: "2.07 ", v3: "2.07 ", v4: "-", v4u: "" },
  { i: "718905", n: "航空施設管理（産業）", v1: "-", v1u: "", v2: "2.62 ", v3: "2.62 ", v4: "-", v4u: "" },
  { i: "718906", n: "その他の航空付帯サービス", v1: "-", v1u: "", v2: "1.32 ", v3: "1.32 ", v4: "-", v4u: "" },
  { i: "718909", n: "旅行・その他の運輸付帯サービス", v1: "-", v1u: "", v2: "0.96 ", v3: "0.96 ", v4: "-", v4u: "" },
  { i: "731101", n: "郵便・信書便", v1: "-", v1u: "", v2: "1.19 ", v3: "1.19 ", v4: "-", v4u: "" },
  { i: "731201", n: "固定電気通信", v1: "-", v1u: "", v2: "1.16 ", v3: "1.16 ", v4: "-", v4u: "" },
  { i: "731202", n: "移動電気通信", v1: "-", v1u: "", v2: "0.85 ", v3: "0.85 ", v4: "-", v4u: "" },
  { i: "731203", n: "その他の電気通信", v1: "-", v1u: "", v2: "1.48 ", v3: "1.48 ", v4: "-", v4u: "" },
  { i: "731909", n: "その他の通信サービス", v1: "-", v1u: "", v2: "1.42 ", v3: "1.42 ", v4: "-", v4u: "" },
  { i: "732101", n: "公共放送", v1: "-", v1u: "", v2: "1.68 ", v3: "1.68 ", v4: "-", v4u: "" },
  { i: "732102", n: "民間放送", v1: "-", v1u: "", v2: "1.47 ", v3: "1.47 ", v4: "-", v4u: "" },
  { i: "732103", n: "有線放送", v1: "-", v1u: "", v2: "1.28 ", v3: "1.28 ", v4: "-", v4u: "" },
  { i: "733101", n: "情報サービス", v1: "-", v1u: "", v2: "1.01 ", v3: "1.02 ", v4: "-", v4u: "" },
  { i: "734101", n: "インターネット附随サービス", v1: "-", v1u: "", v2: "1.37 ", v3: "1.37 ", v4: "-", v4u: "" },
  { i: "735101", n: "映像情報制作・配給業", v1: "-", v1u: "", v2: "2.14 ", v3: "2.14 ", v4: "-", v4u: "" },
  { i: "735102", n: "新聞", v1: "-", v1u: "", v2: "3.68 ", v3: "3.29 ", v4: "-", v4u: "" },
  { i: "735103", n: "出版", v1: "-", v1u: "", v2: "2.88 ", v3: "2.62 ", v4: "-", v4u: "" },
  { i: "735104", n: "ニュース供給・興信所", v1: "-", v1u: "", v2: "1.01 ", v3: "1.01 ", v4: "-", v4u: "" },
  { i: "811101", n: "公務（中央）★★", v1: "-", v1u: "", v2: "1.74 ", v3: "1.74 ", v4: "-", v4u: "" },
  { i: "811201", n: "公務（地方）★★", v1: "-", v1u: "", v2: "1.45 ", v3: "1.45 ", v4: "-", v4u: "" },
  { i: "821101", n: "学校教育（国公立）★★", v1: "-", v1u: "", v2: "0.73 ", v3: "-", v4: "-", v4u: "" },
  { i: "821102", n: "学校教育（私立）★", v1: "-", v1u: "", v2: "1.31 ", v3: "-", v4: "-", v4u: "" },
  { i: "821301", n: "社会教育（国公立）★★", v1: "-", v1u: "", v2: "2.06 ", v3: "-", v4: "-", v4u: "" },
  { i: "821302", n: "社会教育（非営利）★", v1: "-", v1u: "", v2: "2.99 ", v3: "-", v4: "-", v4u: "" },
  { i: "821303", n: "その他の教育訓練機関（国公立）★★", v1: "-", v1u: "", v2: "4.83 ", v3: "-", v4: "-", v4u: "" },
  { i: "821304", n: "その他の教育訓練機関（産業）", v1: "-", v1u: "", v2: "2.73 ", v3: "2.73 ", v4: "-", v4u: "" },
  { i: "822101", n: "自然科学研究機関（国公立）★★", v1: "-", v1u: "", v2: "3.71 ", v3: "3.71 ", v4: "-", v4u: "" },
  { i: "822102", n: "人文科学研究機関（国公立）★★", v1: "-", v1u: "", v2: "2.93 ", v3: "2.93 ", v4: "-", v4u: "" },
  { i: "822103", n: "自然科学研究機関（非営利）★", v1: "-", v1u: "", v2: "2.57 ", v3: "2.57 ", v4: "-", v4u: "" },
  { i: "822104", n: "人文科学研究機関（非営利）★", v1: "-", v1u: "", v2: "1.54 ", v3: "1.54 ", v4: "-", v4u: "" },
  { i: "822105", n: "自然科学研究機関（産業）", v1: "-", v1u: "", v2: "5.50 ", v3: "5.50 ", v4: "-", v4u: "" },
  { i: "822106", n: "人文科学研究機関（産業）", v1: "-", v1u: "", v2: "1.40 ", v3: "1.40 ", v4: "-", v4u: "" },
  { i: "822201", n: "企業内研究開発", v1: "-", v1u: "", v2: "2.63 ", v3: "2.63 ", v4: "-", v4u: "" },
  { i: "831101", n: "医療（国公立）", v1: "-", v1u: "", v2: "2.20 ", v3: "-", v4: "-", v4u: "" },
  { i: "831102", n: "医療（公益法人等）", v1: "-", v1u: "", v2: "1.80 ", v3: "-", v4: "-", v4u: "" },
  { i: "831103", n: "医療（医療法人等）", v1: "-", v1u: "", v2: "1.87 ", v3: "1.87 ", v4: "-", v4u: "" },
  { i: "831201", n: "保健衛生（国公立）★★", v1: "-", v1u: "", v2: "1.60 ", v3: "1.60 ", v4: "-", v4u: "" },
  { i: "831202", n: "保健衛生（産業）", v1: "-", v1u: "", v2: "2.01 ", v3: "2.01 ", v4: "-", v4u: "" },
  { i: "831301", n: "社会保険事業（国公立）★★", v1: "-", v1u: "", v2: "1.55 ", v3: "-", v4: "-", v4u: "" },
  { i: "831302", n: "社会保険事業（非営利）★", v1: "-", v1u: "", v2: "1.80 ", v3: "-", v4: "-", v4u: "" },
  { i: "831303", n: "社会福祉（国公立）★★", v1: "-", v1u: "", v2: "1.49 ", v3: "-", v4: "-", v4u: "" },
  { i: "831304", n: "社会福祉（非営利）★", v1: "-", v1u: "", v2: "1.45 ", v3: "-", v4: "-", v4u: "" },
  { i: "831305", n: "社会福祉（産業）", v1: "-", v1u: "", v2: "1.60 ", v3: "-", v4: "-", v4u: "" },
  { i: "831401", n: "介護（居宅）", v1: "-", v1u: "", v2: "1.34 ", v3: "-", v4: "-", v4u: "" },
  { i: "831402", n: "介護（施設）", v1: "-", v1u: "", v2: "1.60 ", v3: "-", v4: "-", v4u: "" },
  { i: "841101", n: "対企業民間非営利団体", v1: "-", v1u: "", v2: "1.84 ", v3: "1.84 ", v4: "-", v4u: "" },
  { i: "841102", n: "対家計民間非営利団体（除別掲）★", v1: "-", v1u: "", v2: "1.23 ", v3: "-", v4: "-", v4u: "" },
  { i: "851101", n: "広告", v1: "-", v1u: "", v2: "1.86 ", v3: "1.86 ", v4: "-", v4u: "" },
  { i: "851201", n: "物品賃貸業（除貸自動車）", v1: "-", v1u: "", v2: "0.89 ", v3: "0.89 ", v4: "-", v4u: "" },
  { i: "851301", n: "貸自動車業", v1: "-", v1u: "", v2: "1.04 ", v3: "1.04 ", v4: "-", v4u: "" },
  { i: "851410", n: "自動車修理", v1: "-", v1u: "", v2: "2.65 ", v3: "2.65 ", v4: "-", v4u: "" },
  { i: "851510", n: "機械修理", v1: "-", v1u: "", v2: "3.18 ", v3: "3.18 ", v4: "-", v4u: "" },
  { i: "851901", n: "建物サービス", v1: "-", v1u: "", v2: "0.83 ", v3: "0.83 ", v4: "-", v4u: "" },
  { i: "851902", n: "法務・財務・会計サービス", v1: "-", v1u: "", v2: "0.64 ", v3: "0.64 ", v4: "-", v4u: "" },
  { i: "851903", n: "土木建築サービス", v1: "-", v1u: "", v2: "1.30 ", v3: "1.30 ", v4: "-", v4u: "" },
  { i: "851904", n: "労働者派遣サービス", v1: "-", v1u: "", v2: "0.15 ", v3: "0.15 ", v4: "-", v4u: "" },
  { i: "851909", n: "その他の対事業所サービス", v1: "-", v1u: "", v2: "0.69 ", v3: "0.69 ", v4: "-", v4u: "" },
  { i: "861101", n: "映画館", v1: "-", v1u: "", v2: "3.25 ", v3: "-", v4: "-", v4u: "" },
  { i: "861102", n: "興行場（除別掲）・興行団", v1: "-", v1u: "", v2: "1.45 ", v3: "1.45 ", v4: "-", v4u: "" },
  { i: "861103", n: "遊戯場", v1: "-", v1u: "", v2: "2.76 ", v3: "-", v4: "-", v4u: "" },
  { i: "861104", n: "競輪・競馬等の競走場・競技団", v1: "-", v1u: "", v2: "1.66 ", v3: "-", v4: "-", v4u: "" },
  { i: "861105", n: "スポーツ施設提供業・公園・遊園地", v1: "-", v1u: "", v2: "1.76 ", v3: "-", v4: "-", v4u: "" },
  { i: "861109", n: "その他の娯楽", v1: "-", v1u: "", v2: "1.91 ", v3: "1.91 ", v4: "-", v4u: "" },
  { i: "861201", n: "一般飲食店（除喫茶店）", v1: "-", v1u: "", v2: "3.30 ", v3: "-", v4: "-", v4u: "" },
  { i: "861202", n: "喫茶店", v1: "-", v1u: "", v2: "3.19 ", v3: "-", v4: "-", v4u: "" },
  { i: "861203", n: "遊興飲食店", v1: "-", v1u: "", v2: "2.47 ", v3: "-", v4: "-", v4u: "" },
  { i: "861301", n: "宿泊業", v1: "-", v1u: "", v2: "3.24 ", v3: "-", v4: "-", v4u: "" },
  { i: "861401", n: "洗濯業", v1: "-", v1u: "", v2: "2.30 ", v3: "2.30 ", v4: "-", v4u: "" },
  { i: "861402", n: "理容業", v1: "-", v1u: "", v2: "1.39 ", v3: "-", v4: "-", v4u: "" },
  { i: "861403", n: "美容業", v1: "-", v1u: "", v2: "1.21 ", v3: "1.21 ", v4: "-", v4u: "" },
  { i: "861404", n: "浴場業", v1: "-", v1u: "", v2: "5.02 ", v3: "-", v4: "-", v4u: "" },
  { i: "861409", n: "その他の洗濯・理容・美容・浴場業", v1: "-", v1u: "", v2: "1.83 ", v3: "1.83 ", v4: "-", v4u: "" },
  { i: "861901", n: "写真業", v1: "-", v1u: "", v2: "1.46 ", v3: "1.46 ", v4: "-", v4u: "" },
  { i: "861902", n: "冠婚葬祭業", v1: "-", v1u: "", v2: "2.78 ", v3: "2.78 ", v4: "-", v4u: "" },
  { i: "861903", n: "各種修理業（除別掲）", v1: "-", v1u: "", v2: "2.19 ", v3: "2.19 ", v4: "-", v4u: "" },
  { i: "861904", n: "個人教授業", v1: "-", v1u: "", v2: "1.25 ", v3: "1.25 ", v4: "-", v4u: "" },
  { i: "861909", n: "その他の対個人サービス", v1: "-", v1u: "", v2: "1.29 ", v3: "1.29 ", v4: "-", v4u: "" },
  { i: "890000", n: "事務用品", v1: "-", v1u: "", v2: "5.40 ", v3: "5.40 ", v4: "-", v4u: "" },
  { i: "900000", n: "分類不明", v1: "-", v1u: "", v2: "3.29 ", v3: "3.19 ", v4: "-", v4u: "" },
  { i: "909900", n: "内生部門計", v1: "-", v1u: "", v2: "-", v3: "-", v4: "-", v4u: "" },
  { i: "911000", n: "家計外消費支出（列）", v1: "-", v1u: "", v2: "-", v3: "-", v4: "-", v4u: "" },
  { i: "912100", n: "家計消費支出", v1: "-", v1u: "", v2: "-", v3: "-", v4: "-", v4u: "" },
  { i: "970000", n: "総合計", v1: "-", v1u: "", v2: "-", v3: "-", v4: "-", v4u: "" },
];

export default categories;
